.sobre-nos-container {
  background-color: #191818;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.sobre-nos-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sobrenos-row-title {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  margin: 0px;
  font-weight: 500;
}

.sobre-nos-content-image {
  padding-top: 100px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
}

.sobre-nos-content-image.open {
  display: none;
}

.imagem-patrocinio-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imagem-patrocinio-container2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imagens-patrocinios {
  width: 50%;
  height: auto;
}

.patrocinios-container {
  width: 32%;
  height: 120px;
  background-color: #5a5a5a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.container-patrocionios {
  width: 100%;
  max-width: 1200px;
  margin-top: 8vh;
  margin-bottom: 80px;
}

.sobre-nos-content-container {
  width: 100%;
  position: relative;
}

.sobrenos-titulo {
  background: linear-gradient(to bottom, #fce620 0%, #ffc107 50%, #e29603 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sobrenos-subtitle {
  color: white;
  font-family: "Outfit", sans-serif;
  width: 50%;
  font-weight: bold;
  font-family: "Outfit", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

.sobre-nos-image {
  width: 100%;
}

.texto-principal {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  width: 599px;
}

.subtitulo {
  color: #fefefe;
  font-family: "Outfit", sans-serif;
  opacity: 50%;
  font-size: 34px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.subtitulo2-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.subtitulo2 {
  color: #fefefe;
  font-family: "Outfit", sans-serif;
  opacity: 50%;
  font-size: 36px;
  width: 19%;
  margin-bottom: 10px;
  margin-top: 160px;
  font-weight: bold;
}

.subtitulo-linha {
  width: 100%;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sobrenos-text-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 260px;
}

.grid-container {
  margin-bottom: 10px;
}

.sobre-nos-grid-content {
  width: 100%;
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 90px;
  row-gap: 112px;
}

.sobre-nos-grid-item-column {
  /* height: 100px; */
  width: 348px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.grid-item-row {
  display: flex;
  flex-direction: row;
}

.sobrenos-text1 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  width: 348px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .imagem-patrocinio-container2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .imagem-patrocinio {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sobrenos-subtitle {
    font-size: 90px;
    margin-bottom: 10px;
    width: 100%;
  }

  .texto-principal {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 38px;
    width: 100%;
  }

  .container-patrocionios {
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
  }

  .sobrenos-row-title {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 56px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
  }

  .sobre-nos-grid-item-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sobrenos-text1 {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 42px;
    width: 100%;
    margin-top: 20px;
  }

  .sobre-nos-content-image {
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
  }

  .sobre-nos-grid-content {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    justify-items: center;
    align-items: center;
    width: 100%;
  }

  .sobrenos-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 200px;
  }

  .sobre-nos-image {
    height: 60vh;
    object-fit: cover;
  }
  .subtitulo {
    color: white;
    font-family: "Outfit", sans-serif;
    opacity: 75%;
    margin-bottom: 100px;
    margin-top: 5px;
    font-size: 60px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
  }

  .subtitulo2 {
    color: #fefefe;
    font-family: "Outfit", sans-serif;
    opacity: 50%;
    font-size: 72px;
    width: 100%;
    margin-bottom: 50px;
    text-align: start;
  }

  .patrocinios-container {
    width: 100%;
    height: 200px;
    background-color: #5a5a5a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1500px) {
  .imagem-patrocinio {
    width: 83%;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .subtitulo2 {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .sobrenos-subtitle {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 48px;
    width: 30%;
    font-weight: bold;
  }

  .sobre-nos-grid-content {
    width: 100%;
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 90px;
    row-gap: 50px;
  }

  .subtitulo-linha {
    width: 100%;
    /* height: 390px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    max-width: 100%;
  }

  .texto-principal {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 20px;
  }

  .sobrenos-text-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 260px;
    max-width: 100%;
  }

  .container-patrocionios {
    width: 100%;
    max-width: 100%;
    margin-top: 8vh;
  }

  .subtitulo {
    margin-bottom: 30px;
  }
}
