.home-container {
  background-color: #191818;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.container-roww {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  white-space: normal;
}

.container-roww-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.home-row-img {
  max-height: 495px;
}

.home-row-content {
  max-width: 100%;
}

.home-text2 {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
}

.home-text2-subtitle {
  width: 100%;
  font-size: 34px;
  color: white;
  font-family: "Outfit", sans-serif;
  text-align: center;
}

.home-text3-subtitle {
  width: 100%;
  font-size: 20px;
  color: white;
  font-family: "Outfit", sans-serif;
  text-align: center;
  margin-top: 10px;
}

.sub-titulo-progress-bar {
  font-size: 16px;
  color: white;
  font-family: "Outfit", sans-serif;
  margin-top: 10px;
}

.sub-titulo-progress-bar.active {
  color: white;
}

.row {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: 12vh;
}

.column-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.column {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.grid-item-column2 {
  flex-direction: column;
  width: 50%;
}

.grid-item-column3 {
  display: flex;
  flex-direction: column;
  width: 25%;
  justify-content: space-between;
}

.grid-interior-row {
  width: 85%;
  display: flex;
  flex-direction: row;
}

.grid-interior-row2 {
  width: 90%;
  display: flex;
  flex-direction: row;
}

.home-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.img-phone-home {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home-container.open {
  display: none;
  overflow: hidden;
}

.home-content {
  padding: 2px;
  padding-top: 92px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
  margin-bottom: 100px;
}

.home-title {
  background: linear-gradient(
    25deg,
    rgba(226, 150, 3, 1) 0%,
    rgba(255, 193, 7, 1) 75%,
    rgba(252, 230, 32, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Outfit", sans-serif;
  font-weight: 550;
  font-size: 102px;
  text-align: center;
  width: 80%;
  margin-bottom: 20px;
}

.home-subtitle {
  color: #fdf7e4;
  font-family: "Outfit", sans-serif;
}

.home-row {
  /* height: 80px; */
  /* width: 540px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.home-row-tec {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.home-row-content-img {
  width: 100%;
  height: auto;
}

.home-text1 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 50px;
  width: 1100px;
  text-align: center;
  font-weight: bold;
}

.home-text1new {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 48px;
  text-align: center;
  width: 100%;
}

.home-text1new-grey {
  color: #979797;
}

.home-text2new {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 48px;
  text-align: center;
  width: 100%;
}

.home-text2 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 34px;
  text-align: center;
  margin-top: 5vh;
}

.home-text3 {
  font-family: "Outfit", sans-serif;
  font-size: 34px;
  text-align: left;
}

.home-text3.large {
  color: black;
  font-family: "Outfit", sans-serif;
  font-size: 34px;
  text-align: left;
}

.home-text3.small {
  color: gray;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  text-align: left;
  font-weight: 600;
}

.home-subtext2 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 5vh;
}

.grid-container {
  margin-bottom: 100px;
  width: 100%;
}

.grid-content {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid-item-row-home {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
}

.home-row-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  margin: 0px;
  width: 80%;
}

.home-row-title-tec {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 28px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: lighter;
}

.text1 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  width: 313px;
  height: 100px;
  align-items: center;
  opacity: 75%;
}

.home-row-img-topico {
  width: 27px;
  height: 27px;
  margin-right: 15px;
}

.home-row-img-topico3 {
  width: 27px;
  height: 27px;
  margin-right: 15px;
}

.navbar-option-subcontainer {
  width: 100%;
}

.navbar-option-subcontainer-column {
  width: 100%;
}

.navbar-option-subcontainer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-row-img-topico2 {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.grid-item-column {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-tec-item {
  width: 330px;
  height: 170px;
  border: 1px solid #fefefe;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 10px;
  margin-bottom: 20px;
}

.home-tec-text {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 15px;
  height: 100%;
  align-items: center;
}

.home-row-tec-container-home {
  /* height: 900px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-row-tec-container-home-content {
  /* height: 700px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-country-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* new */

.sub-titulo-animacao {
  font-family: Arial, sans-serif;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.sub-titulo-animacao2 {
  margin-top: 10px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
}

.sub-titulo-animacao2.large {
  color: black;
}

.sub-titulo-animacao2.small {
  color: gray;
  height: 50px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.home-animacao-content {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titulo-menu-escolhas {
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: start;
  font-family: Arial, sans-serif;
  color: #fff;
}

.container-new {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
}

.topics-column {
  /* flex: 1; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.yellow-dot {
  position: relative;
  height: 15px;
  width: 15px;
  background-color: #ffc107;
  border-radius: 100%;
  display: inline-block;
  margin-right: 7.5px;
}

.yellow-dot::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -55%);
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
}

.white-dot {
  position: relative;
  height: 0.5rem;
  width: 0.5rem;
  background-color: white;
  border-radius: 100%;
  display: inline-block;
  margin-right: 6px;
}

.dot-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 5px;
  padding-right: 10px;
}

.section {
  /* width: 100%; */
  position: relative;
  padding: 10px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 12vh;
}

.section.active {
  opacity: 1;
}
/* .section:hover {
  background-color: #333;
} */
.progress-bar {
  width: 100%;
  height: 2px;
  background-color: #555;
  bottom: -5px;
  left: 0;
}
.progress-bar-fill {
  height: 100%;
  width: 0;
  background-color: #ffc107;
  transition: width 0.1s linear;
}
.section h3 {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
.images-column {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.image-container {
  width: 50%;
  /* width: 100%;
  max-width: 600px; */
  height: auto;
  position: relative;
}
.image-containerimg {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 1px solid #555;
}
.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #333;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
/* new */
.app-container {
  display: flex;
  flex-wrap: wrap; /* Permite que os cartões quebrem para a próxima linha se necessário */
  justify-content: center; /* Centraliza os cartões horizontalmente */
  /* padding-bottom: 20vh; */
  height: auto;
  white-space: normal;
}

.home-text2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.sub-titulo-animacao {
  font-size: 18px;
  margin-bottom: 20px;
}
.card-container {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap;
  justify-content: center;
  gap: 10px; */
  flex-direction: row;
  justify-content: space-between;
}

.alling-itens {
  display: flex;
  flex-wrap: wrap; /* Permite que os cartões quebrem para a próxima linha se necessário */
  justify-content: center; /* Centraliza os cartões horizontalmente */
}

.lottie {
  width: 100%;
  height: 30%;
}

.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  height: auto;
}

.card {
  height: 800px;
  flex: 1;
  /* width: 30%; */
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid #272626;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px; /* Define o raio da borda para 10 pixels */
  background-color: #272626; /* Cor de fundo para os não selecionados */
  color: white; /* Cor do texto para contraste */
  position: relative;
}

.home-lottie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card img {
  width: 100%;
  height: auto;
}

.card.small {
  padding: 0;
  flex: 1;
}

.card.large {
  padding: 0;
  flex: 2;
  background-color: #ffc107;
}

.text-overlay {
  position: absolute;
  bottom: 5%;
  left: 5%;
  z-index: 2;
  text-align: center;
  color: #ffffff;
}

.text-overlay.large {
  width: 450px;
}

.text-overlay.small {
  width: 240px;
}

/* .card.small {
  flex: 1;
} */

.card.large .home-text2,
.card.large .sub-titulo-animacao {
  font-size: 20px;
}

.card.small .home-text2,
.card.small .sub-titulo-animacao {
  font-size: 16px;
}

.display-image {
  width: 40%;
  margin-top: 10px;
  margin-bottom: 30px;
}

.home-title-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home-subtitle-container {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1200px) {
  /* .game-image {
    width: 50%;
  } */

  .home-row-container {
    margin-bottom: 50px;
  }

  .home-row-img-topico3 {
    width: 60px;
    height: auto;
    margin-right: 30px;
  }

  .home-column-section {
    width: 100%;
    position: relative;
    /* padding: 10px; */
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .home-column-section.active {
    opacity: 1;
  }

  .home-column-section.others {
    margin-top: 70px;
  }

  .home-row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2px;
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
    margin-bottom: 180px;
  }

  .card {
    height: 150px;
    transition: all 0.3s ease;
    border: 2px solid #272626;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 20px; /* Define o raio da borda para 10 pixels */
    background-color: #272626; /* Cor de fundo para os não selecionados */
    color: white; /* Cor do texto para contraste */
  }

  .home-text1 {
    font-size: 40px;
  }

  .home-text2 {
    font-size: 30px;
  }

  .home-subtitle {
    width: 100%;
    font-size: 45px;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 50px;
  }

  .card-container {
    height: 4000px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
  }

  .card.large .home-text2,
  .card.large .sub-titulo-animacao {
    font-size: 10px;
  }

  .card.small .home-text2,
  .card.small .sub-titulo-animacao {
    font-size: 12px;
  }

  .navbar {
    padding: 2vh 0;
    width: 100%;
  }

  .navbar-option-image-container {
    width: 30%;
  }

  .navbar-img {
    width: 100%;
  }

  .navbar-content {
    width: 80%;
    padding: 0 80px;
    display: flex;
    flex-direction: row;
  }

  .visit-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 101;
  }

  .visit-menu.open {
    position: absolute;
    width: 50px;
    top: 50;
    right: 100;
  }

  .visit-menu div {
    width: 50px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: all 0.3s ease;
  }

  .visit-menu.open div {
    width: 65px;
    height: 5px;
    background-color: white;
    transition: all 0.3s ease;
  }

  .visit-menu.open div:nth-child(1) {
    transform: rotate(45deg) translate(10px, 15px);
  }

  .visit-menu.open div:nth-child(2) {
    opacity: 0;
  }

  .visit-menu.open div:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -15px);
  }

  .navbar-option-container {
    width: 7%;
    justify-content: center;
  }

  .home-row-tec {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .navbar-option-subcontainer {
    background-color: #191818;
    z-index: 100;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    opacity: 0;
  }

  .navbar-option {
    width: 100%;
    font-size: 3vh;
  }

  .navbar-subcontainer-column {
    height: 100%;
    width: 100%;
  }

  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 100vh;
      opacity: 1;
    }
  }

  .navbar-option-subcontainer.active {
    display: flex;
    max-height: 100%;
    opacity: 1;
    animation: slideDown 0.3s forwards;
  }

  .grid-item-row-navbar {
    margin: 0;
    justify-content: start;
  }

  .navbar-option-subcontainer-content {
    position: absolute;
    top: 150;
    left: 100;
    height: 32%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-row-img-language {
    display: flex;
    flex-direction: row;
    height: 3.5vh;
    margin-right: 30px;
    width: auto;
  }

  .grid-content {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 32px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 5.5vh;
  }

  .home-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .home-title {
    background: linear-gradient(
      25deg,
      rgba(226, 150, 3, 1) 0%,
      rgba(255, 193, 7, 1) 75%,
      rgba(252, 230, 32, 1) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Outfit", sans-serif;
    font-weight: 550;
    font-size: 90px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .home-subtitle-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  /* 350 390 */
  .home-row-img.apple {
    width: 350px;
  }

  .home-row-img.google {
    width: 390px;
  }

  .img-phone-home {
    width: 100%;
  }

  .home-text1new {
    font-size: 70px;
  }

  .home-text2new {
    font-size: 70px;
    margin-top: 180px;
  }

  .topics-column {
    width: 100%;
    padding: 0px;
  }

  .titulo-menu-escolhas {
    font-size: 50px;
  }

  .display-image {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .sub-titulo-progress-bar {
    font-size: 35px;
    margin-top: 20px;
  }

  .container-roww {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container-roww-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-animacao-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-text2-subtitle {
    width: 100%;
    font-size: 50px;
    color: white;
    font-family: "Outfit", sans-serif;
    text-align: start;
    margin-top: 50px;
  }

  .home-text3-subtitle {
    width: 100%;
    font-size: 35px;
    color: white;
    font-family: "Outfit", sans-serif;
    text-align: start;
    margin-top: 30px;
  }

  .home-text3.small {
    color: #e7e7e7;
    font-family: "Outfit", sans-serif;
    font-size: 50px;
    text-align: left;
    font-weight: 600;
  }

  .text-overlay.small {
    width: 700px;
  }

  .sub-titulo-animacao2 {
    margin-top: 10px;
    font-family: "Outfit", sans-serif;
    font-size: 40px;
    text-align: left;
    line-height: 1.5;
  }

  .sub-titulo-animacao2.small {
    color: #e7e7e7;
    /* height: 420px; */
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .overlay-image {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1200px) {
  .container-roww-content {
    max-height: 495px;
  }

  .home-column-section {
    position: relative;
    padding: 10px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 16vh;
  }

  .home-column-section.active {
    opacity: 1;
  }

  .home-row {
    height: 80px;
    width: 24%;
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
  }

  .row {
    width: 80%;
    display: flex;
    flex-direction: row;
  }

  .home-row-content-img2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .grid-item-column2 {
    flex-direction: column;
    width: 50%;
  }

  .grid-item-column3 {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: space-between;
  }

  .column-center {
    display: none;
  }

  .column-center {
    height: 100vh;
  }
}

@media (min-width: 1500px) {
  .home-row-tec {
    justify-content: start;
    text-align: center;
  }

  .home-subtitle {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 20px;
    width: 100%;
    text-align: center;
  }

  .home-subtitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400px;
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2vh;
  }

  .home-row-content-img2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .home-row-content-img {
    height: auto;
    width: 60%;
  }

  .home-text1 {
    width: 100%;
  }

  .home-text1new {
    width: 38%;
  }

  .img-phone-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .home-content {
    padding: 2px;
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-title {
    width: 100%;
    max-width: 100%;
  }

  .grid-container {
    width: 100%;
    max-width: 1200px;
  }

  .home-text2 {
    width: 100%;
  }

  .grid-content {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 8vh;
  }

  .home-row-tec {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    justify-content: space-between;
  }

  .row {
    width: 80%;
    display: flex;
    flex-direction: row;
  }

  .grid-item-column2 {
    flex-direction: column;
    width: 50%;
  }

  .grid-item-column3 {
    flex-direction: column;
    width: 25%;
  }
  .grid-item-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

/* @media (max-width: 900px) {
  .navbar {
    padding: 2vh 0;
    width: 100%;
  }

  .column {
    height: 100%;
  }

  .img-phone-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .home-container {
    overflow-x: hidden;
  }

 .home-content {
    padding: 2px;
    padding-top: 100px;
    padding-right: 300px;
    padding-left: 300px;
    padding-bottom: 100px;
    margin-bottom: 100px;
  }

  .home-title {
    font-size: 3.8vh;
    margin-bottom: 14px;
  }

  .home-subtitle {
    margin-top: 3vh;
    font-size: 2.5vh;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-row {
    width: 70%;
  }

  .home-row-content-img {
    height: auto;
    width: 100%;
  }

  .home-row-content-img2 {
    height: auto;
    width: 100%;
  }

  .home-row-title {
    display: flex;
    align-items: center;
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 20px;
    margin: 0px;
    width: 100%;
  }

  .grid-item-row-home {
    display: flex;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 50px;
    width: 100%;
  }

  .text1 {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    width: 80%;
    height: 100px;
    opacity: 75%;
    text-align: center;
  }

  .home-text1 {
    margin-top: 5vh;
    font-size: 2.9vh;
    width: 100%;
  }

  .home-text1new {
    margin-top: 5vh;
    font-size: 2.9vh;
    width: 100%;
  }

  .home-text2 {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
    margin-top: 6vh;
  }

  .home-row-title-tec {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 40px;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: lighter;
  }

  .home-row-img {
    width: 200px;
  }

  .home-row {
    height: 80px;
    width: 540px;
    display: flex;
    margin-top: 3vh;
    margin-bottom: 4vh;
    flex-direction: row;
    justify-content: space-between;
  }

  .home-row-img-topico {
    width: 50px;
    height: auto;
    margin-right: 0.2vh;
  }

  .grid-item-column {
    width: 100%;
    align-items: center;
    font-size: 68px;
  }

  .home-tec-item {
    width: 90%;
    border: 1px solid #fefefe;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }

  .home-tec-text {
    font-size: 22px;
  }

  .grid-interior-row {
    width: 90%;
    display: flex;
    flex-direction: row;
  }

  .grid-interior-row2 {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  .navbar-option-image-container {
    width: 30%;
  }

  .navbar-img {
    width: 100%;
  }

  .navbar-content {
    width: 80%;
    padding: 0 80px;
    display: flex;
    flex-direction: row;
  }

  .visit-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 101;
  }

  .visit-menu.open {
    position: absolute;
    width: 50px;
    top: 50;
    right: 100;
  }

  .visit-menu div {
    width: 50px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: all 0.3s ease;
  }

  .visit-menu.open div {
    width: 65px;
    height: 5px;
    background-color: white;
    transition: all 0.3s ease;
  }

  .visit-menu.open div:nth-child(1) {
    transform: rotate(45deg) translate(10px, 15px);
  }

  .visit-menu.open div:nth-child(2) {
    opacity: 0;
  }

  .visit-menu.open div:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -15px);
  }

  .navbar-option-container {
    width: 7%;
    justify-content: center;
  }

  .home-row-tec {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .navbar-option-subcontainer {
    background-color: #191818;
    z-index: 100;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    opacity: 0;
  }

  .navbar-option {
    width: 100%;
    font-size: 3vh;
  }

  .navbar-subcontainer-column {
    height: 100%;
    width: 100%;
  }

  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 100vh;
      opacity: 1;
    }
  }

  .navbar-option-subcontainer.active {
    display: flex;
    max-height: 100%;
    opacity: 1;
    animation: slideDown 0.3s forwards;
  }

  .grid-item-row-navbar {
    margin: 0;
    justify-content: start;
  }

  .navbar-option-subcontainer-content {
    position: absolute;
    top: 150;
    left: 100;
    height: 32%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-row-img-language {
    display: flex;
    flex-direction: row;
    height: 3.5vh;
    margin-right: 30px;
    width: auto;
  }

  .grid-content {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 32px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 5.5vh;
  }
}

@media (max-width: 768px) {
  .home-title {
    font-size: 2.8vh;
  }

  .home-subtitle {
    font-size: 2vh;
  }

  .text1 {
    font-size: 16px;
  }

  .home-row-title {
    font-size: 20px;
  }

  .home-row-title-tec {
    font-size: 15px;
  }

  .home-tec-item {
    width: 100%;
  }
  .home-text2 {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 30px;
    display: flex;
    justify-content: center;
  }

  .home-tec-text {
    font-size: 13px;
  }
}

@media (max-width: 820px) {
   .home-title {
    font-size: 42px;
  }

  .home-text1 {
    font-size: 32px;
  }
  .home-text2 {
    font-size: 26px;
  }
  .home-subtitle {
    font-size: 18px;
  }
  .container-roww-content {
    width: 100%;
    padding: 15px;
  }
  .topic {
    margin: 30px 0;
  }
  .sub-titulo-progress-bar {
    font-size: 22px;
  }
  .display-image {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
  }
} */
