.contactos-containerr {
  background-color: #191818;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.contactos-text-container {
  background-color: #272727;
  width: 100%;
  border-radius: 1vh;
  margin-top: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-contactos {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-container {
  width: 650px;
}

.contactoss-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 2px;
  margin-bottom: 100px;
  padding-top: 100px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
}

.contactos-titulo {
  font-family: "Outfit", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

.complementos-titulo-page {
  font-family: "Outfit", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

.gradient-text {
  background: linear-gradient(to bottom, #fce620 0%, #ffc107 50%, #e29603 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contactos-subtitulo {
  color: white;
}

.lottie-container {
  height: 550px;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nome {
  margin-bottom: 40px;
  font-size: 34px;
  font-family: "Outfit", sans-serif;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: 0 0 5px 191818;
  border-bottom: 2px solid rgba(254, 254, 254, 0.5);
  color: white;
}

/* input::placeholder,
textarea::placeholder {
  color: #cccccc;
} */

textarea {
  height: 150px;
  resize: none;
}

.button {
  background: linear-gradient(to bottom, #fce620 0%, #ffc107 50%, #e29603 100%);
  border: none;
  color: black;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 7px;
}

.texto-contactos1 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  width: 88%;
  margin-bottom: 20px;
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.texto-contactos2 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  width: 42%;
  margin-bottom: 20px;
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.texto-contactos-numero-phone {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 40px;
  margin-left: 1.6vh;
  margin-right: 12vh;
  margin-top: 1.6vh;
  margin-bottom: 1.5vh;
  width: 30vh;
}

.texto-titulo-phone {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 34px;
  margin-top: 2.5vh;
  margin-bottom: 3vh;
}

.texto-loc {
  color: white;
  margin-bottom: 10px;
}

.phone-number {
  color: white;
  font-family: "Outfit", sans-serif;
}

.contactos-container {
  margin-top: 100px;
  width: 100%;
  height: 180px;
  background-color: #272727;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.contactos-container-content {
  width: 35%;
}

.home-row-text-loc {
  color: white;
  font-family: "Outfit", sans-serif;
  margin: 0px;
  width: 100%;
  margin-left: 10px;
}

.home-row-img-loc {
  display: flex;
  flex-direction: row;
  height: 30;
  width: auto;
  margin-top: 0;
  margin-right: 0.5vh;
  margin-bottom: 0.5vh;
}

.home-row-text-phone {
  color: white;
  font-family: "Outfit", sans-serif;
  margin: 0px;
  width: 100%;
  margin-left: 10px;
  margin-top: 20px;
}

.home-row-img-phone {
  display: flex;
  flex-direction: row;
  height: 25px;
  width: auto;
  margin-top: 15vh;
  margin-left: 25px;
}

@media (max-width: 1200px) {
  .contactos-containerr {
    overflow-x: hidden;
  }

  .container-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contactos-titulo {
    font-family: "Outfit", sans-serif;
    font-size: 90px;
    font-weight: bold;
    font-weight: bold;
    width: 100%;
  }

  .complementos-titulo-page {
    font-family: "Outfit", sans-serif;
    font-size: 90px;
    font-weight: bold;
    font-weight: bold;
    width: 100%;
  }

  .contactoss-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 2px;
    margin-bottom: 250px;
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
  }

  .home-row-img-phone {
    display: flex;
    flex-direction: row;
    height: 25px;
    width: auto;
    margin-top: 15px;
    margin-left: 25px;
    margin-left: 1vh;
  }

  .home-row-img-loc {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: auto;
    margin-right: 30px;
    margin-bottom: 0.5vh;
  }

  .contactos-text-container {
    background-color: #272727;
    width: 100%;
    border-radius: 1vh;
    margin-top: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .contactos-container {
    width: 25vh;
    height: auto;
    background-color: #272727;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    border-radius: 10px;
    margin-right: 0;
    box-sizing: border-box;
    padding: 0vh;
  }
  .contactos-container-content {
    width: 90vh;
    box-sizing: border-box;
  }

  .container-contactos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space;
    box-sizing: border-box;
  }

  .input-container {
    width: 100%;
    margin-top: 4vh;
    box-sizing: border-box;
  }

  /* .contactos-titulo {
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #ffffff 50%,
      #ffffff 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  } */

  .nome {
    font-size: 60px;
    padding: 20px;
  }

  .button {
    font-size: 30px;
    height: 70px;
    width: 280px;
    margin-top: 60px;
  }

  .texto-titulo-phone {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 45px;
    margin-left: 1.6vh;
    margin-top: 1.6vh;
    margin-bottom: 1.5vh;
  }

  .texto-contactos1 {
    font-size: 32px;
    margin-left: 1.6vh;
    margin-top: 1.6vh;
    margin-bottom: 1.5vh;
    width: 500px;
  }

  .texto-contactos2 {
    font-size: 32px;
    margin-left: 1.6vh;
    margin-top: 1.6vh;
    margin-bottom: 1.5vh;
    /* width: 100%; */
    width: 340px;
    align-items: center;
  }

  .texto-contactos-numero-phone {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 40px;
    margin-left: 1.6vh;
    margin-right: 12vh;
    margin-top: 1.6vh;
    margin-bottom: 1.5vh;
    width: 100%;
  }

  .home-row-text-phone {
    color: white;
    font-size: 28px;
    font-family: "Outfit", sans-serif;
    margin: 0px;
    width: 100%;
    margin-left: 10px;
    margin-top: 0.5vh;
  }

  .home-row-text-loc {
    font-size: 28px;
    height: 11vh;
    color: white;
    font-family: "Outfit", sans-serif;
    margin: 0px;
    width: 300px;
    margin-left: 10px;
  }

  .contactos-titulo {
    font-family: "Outfit", sans-serif;
    font-size: 90px;
    width: 25vh;
    font-weight: bold;
  }

  .complementos-titulo-page {
    font-family: "Outfit", sans-serif;
    font-size: 90px;
    font-weight: bold;
  }

  textarea {
    height: 300px;
    resize: none;
  }
}

@media (min-width: 1500px) {
  .contactos-titulo {
    font-family: "Outfit", sans-serif;
    font-size: 48px;
    font-weight: 500;
  }

  .complementos-titulo-page {
    font-family: "Outfit", sans-serif;
    font-size: 48px;
    font-weight: 500;
  }

  .container-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input-container {
    width: 80vh;
  }

  .contactos-text-container {
    background-color: #272727;
    height: 245px;
    width: 100%;
    border-radius: 1vh;
    margin-top: 8vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .contactoss-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 2px;
    margin-bottom: 100px;
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
  }

  .container-contactos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
  }
}
