.titulo {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 3vw;
  font-weight: bold;
  text-align: start;
}

.complementos-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 2px;
  margin-bottom: 100px;
  padding-top: 100px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
  min-width: fit-content;
  min-height: fit-content;
}

.text-introducao {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  width: 755px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: lighter;
}

.complementos-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
}

.imagem-complementos {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imagens-complementos {
  width: 97%;
  border-radius: 8px;
}

.container-imagem {
  width: 100%;
  position: relative;
}

.complementos-container {
  position: absolute;
  bottom: 50;
  left: 0;
  right: 0;
}

.complementos-subtitulo-img {
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  width: 520px;
  color: white;
  margin-top: 15px;
  margin-left: 11px;
}

.complementos-titulo {
  background-color: white;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 48px;
  text-align: center;
}

.complementos-titulo-img {
  margin-top: 16px;
  margin-left: 11px;
  font-size: 45px;
  font-weight: bold;
  background: linear-gradient(to bottom, #fce620 0%, #ffc107 50%, #e29603 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Outfit", sans-serif;
}

@media (max-width: 1200px) {
  .text-introducao {
    text-align: left;
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }

  .complementos-text-container {
    flex-direction: column;
    align-items: left;
    margin-bottom: 50px;
  }

  .complementos-titulo-img {
    font-size: 80px;
    width: auto;
    margin-left: 0;
    margin-bottom: 50;
    padding: 0 10px;
    text-align: left;
  }

  .imagem-complementos {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-imagem {
    height: 930px;
    width: 100%;
    margin-bottom: 100px;
  }

  .imagens-complementos {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .complementos-container {
    /* position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%); */
    padding: 10px;
    text-align: center;
    color: white;
    width: 90%;
  }

  .complementos-subtitulo-img {
    font-size: 41px;
    margin: 15px 0 0 0;
    width: 100%;
    padding: 0 10px;
    text-align: start;
  }

  .titulo {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 72px;
    font-weight: bold;
    text-align: start;
    margin-bottom: 2vh;
  }
}

@media (min-width: 1500px) {
  /* .imagem-complementos {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  } */

  .imagem-complementos {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 32px;
    display: grid;
    row-gap: 80px;
    column-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .complementos-text-container {
    height: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .complementos-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 2px;
    margin-bottom: 100px;
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
  }

  .titulo {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 76px;
    font-weight: bold;
    text-align: start;
  }

  .text-introducao {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 20px;
    width: 755px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .imagem-complementos {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .complementos-text-container {
    width: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .complementos-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 2px;
    margin-bottom: 100px;
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
  }

  .titulo {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 2rem;
    font-weight: bold;
    text-align: start;
  }

  .text-introducao {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 20px;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
