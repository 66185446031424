.servicos-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 2px;
  padding-top: 100px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
}

.servicos-titulo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* font-family: "Outfit", sans-serif;
  font-size: 48px;
  font-weight: bold; */
}

.servicos-gradient-text {
  color: white;
}

.servicos-subtitulo {
  background: linear-gradient(to bottom, #fce620 0%, #ffc107 50%, #e29603 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.imagens-servicos {
  width: 40%;
  border-radius: 8px;
}

/* .home-container {
  padding: 20px;
} */

/* .home-container.open {
  margin-left: 250px;
} */

.complementos-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .complementos-text-container {
  width: 100%;
} */

.titulo-texto {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 34px;
}

/* .textservicos {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 10;
} */

.implementacao {
  color: white;
  font-family: "Outfit", sans-serif;
}

.tecnologias {
  color: white;
  font-family: "Outfit", sans-serif;
}

.titulo {
  background: linear-gradient(to bottom, #fce620 0%, #ffc107 50%, #e29603 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
}

.funcionalidades-section {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
}

.funcionalidade-item {
  border-radius: 8px;
  /* padding: 20px; */
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.servicos-texto1 {
  width: 60%;
}

.funcionalidade-item h3 {
  margin-top: 0;
}

.implementacao,
.tecnologias {
  margin-top: 10px;
}

.implementacao h1,
li,
.tecnologias h1,
li {
  font-size: 16px;
}

.implementacao h1,
.tecnologias h1 {
  letter-spacing: 0.5;
  margin-bottom: 10px;
}

.implementacao li,
.tecnologias li {
  margin-bottom: 5px;
  margin-left: 5px;
}

.implementacao h4,
.tecnologias h4 {
  margin-bottom: 5px;
}

.implementacao ul,
.tecnologias ul {
  margin: 0;
  padding-left: 20px;
}

/* .complementos-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
} */

@media (max-width: 1200px) {
  .servicos-content{
    margin-bottom: 180px;
  }

  .funcionalidade-item {
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .imagens-servicos {
    width: 100%;
    border-radius: 8px;
  }

  .servicos-texto1 {
    width: 100%;
  }

  .titulo-texto {
    color: white;
    font-family: "Outfit", sans-serif;
    font-size: 70px;
  }

  .funcionalidades-section {
    gap: 100px;
  }

  .implementacao,
  .tecnologias {
    margin-top: 50px;
  }

  .implementacao h1,
  li,
  .tecnologias h1,
  li {
    font-size: 38px;
  }

  .implementacao li,
  .tecnologias li {
    margin-bottom: 10px;
    margin-left: 5px;
    line-height: 1.2;
  }
}

@media (min-width: 1500px) {
  .funcionalidade-item {
    padding: 20px;
  }

  .funcionalidades-section {
    gap: 20px;
  }
}
