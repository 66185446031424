.navbar {
  background-color: #151515;
  height: 97px;
  width: 100%;
  /* padding: 10px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-content {
  width: 100%;
  max-width: 90%  ;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-option-image-container {
  display: flex;
  justify-content: center;
}

.navbar-img {
  width: 249px;
  height: auto;
}

.navbar-option-container {
  display: flex;
  align-items: center;
  width: 600px;
}

.contactos-container-img {
  width: 60px;
}

.navbar-subcontainer-column {
  width: 100%;
}

.navbar-option {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-option2 {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-left: 25px;
}

.navbar-option:hover,
.navbar-option.active {
  background: linear-gradient(to bottom, #fce620 0%, #ffc107 50%, #e29603 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.visit-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 10;
}
.navbar-option-subcontainer {
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.navbar-option-subcontainer.active {
  max-height: 500px;
  opacity: 1;
}

.home-row-img-language {
  display: flex;
  flex-direction: row;
  height: 25px;
  width: auto;
}

.grid-item-row-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
